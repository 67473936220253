<template>
  <div class="my-16">

    <div class="text-center">
      <img src="/images/mail.png" alt="">
    </div>
    <div class="text-center">
      <h2 class="text-primary mb-2">Dear {{ studentInfo ? studentInfo.name : '' }}, </h2>
      <p class="text-base line-height-27 w-75p mx-auto">Thank you for joining at APICAL as a Student. All the
        instruction has been sent to your email address {{ studentInfo ? studentInfo.email : '' }}. And
        We have also sent you a verification code in your contact number.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      studentInfo: {}
    }
  },
  methods: {
    getStudentInfoDetails() {
      let studentDetails = this.$route.params;
      if(typeof studentDetails !== "undefined" && Object.keys(studentDetails).length != 0){
        this.studentInfo = this.$route.params;
      }else{
        this.$router.push({
          name: 'basicInformation'
        });
      }
    }
  },
  mounted() {
    this.getStudentInfoDetails();
    this.remove("registration-student-info");
    this.remove("registration-student-info-menu");
    this.remove("registration-success");
  }
}
</script>