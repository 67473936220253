<template>
  <div>
    <div class="p-4 rounded" style="background: #f7f4fc">
      <div>
        <p class="text-base font-semibold text-italic opacity-75 text-uppercase">Branch Address</p>
        <address class="font-semibold line-height-22 mt-2">
          APICAL Dhanmondi Branch <br>
          House No: 601, Road No: 09, Baitul Amana Housing Socity, Adabor <br>
          Dhaka 1207, Bangladesh
        </address>
      </div>
      <div class="my-4">
        <p class="text-base mb-2 font-semibold text-italic opacity-75 text-uppercase">Branch Contact</p>
        <h3 class="opacity-75">+88 01717 123 123</h3>
      </div>

      <div class="my-4 branch-opening-days">
        <p class="text-base font-semibold text-italic opacity-75 text-uppercase">Working Day</p>
        <template v-for="day in days">
          <md-checkbox :class="{'opacity-50' : !day.isOpen }" :disabled="!day.isOpen" v-model="day.isOpen"
                       :key="day.key">{{ day.value }}
          </md-checkbox>
        </template>
      </div>
      <div class="my-4">
        <p class="text-base mb-2 font-semibold text-italic opacity-75 text-uppercase">Working Houre</p>
        <h3 class="opacity-75">10:00 AM ~ 06:00 PM</h3>
      </div>
    </div>

    <div class="my-4">
      <h2 class="text-uppercase text-base mb-4">Your Prefered Date & Time</h2>
      <div class="md-layout p-3 rounded" style="border: 1px solid #7432b8">
        <div class="md-layout-item md-size-40">
          <Datepicker label="Date"/>
        </div>
        <div class="md-layout-item md-size-30">
          <label class="pr-4 inline-block mb-1 text-uppercase font-bold">Time</label>
          <vue-timepicker
              close-on-complete
              v-model="time"
              format="mm:ss"
          ></vue-timepicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from '@/components/atom/Datepicker';
import VueTimepicker from 'vue2-timepicker';

export default {
  components: {
    Datepicker,
    VueTimepicker,
  },

  data() {
    return {
      time: '',
      days: [
        {
          key: 'sun',
          value: 'SUN',
          isOpen: true,
        },

        {
          key: 'mon',
          value: 'Mon',
          isOpen: true,
        },
        {
          key: 'tue',
          value: 'TUE',
          isOpen: true,
        },
        {
          key: 'wed',
          value: 'WED',
          isOpen: true,
        },
        {
          key: 'thr',
          value: 'Thr',
          isOpen: true,
        },
        {
          key: 'fri',
          value: 'Fri',
          isOpen: false,
        },
        {
          key: 'sat',
          value: 'Sat',
          isOpen: false,
        },

      ]
    }
  },
}
</script>