<template>
    <div>
        <div class="flex align-center">
            <img src="/images/logo.alt.png" alt="">
            <div class="ml-2 flex align-center">
                <div class="mr-2 px-2 py-1 text-basis text-uppercase bg-victoria text-white rounded inline-block">Student Registration</div>
                <div class="outline-gray-400 px-2 py-1 inline-flex align-center rounded">
                    <svg class="w-4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" fill="#5B5B5B">
                        <path d="M12,2C8.134,2,5,5.134,5,9c0,5,7,13,7,13s7-8,7-13C19,5.134,15.866,2,12,2z M12,11.5c-1.381,0-2.5-1.119-2.5-2.5 c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5C14.5,10.381,13.381,11.5,12,11.5z" fill="#6c2bb7" />
                    </svg>
                    Udvash Coaching, Dhanmondi, Dhaka
                </div>
            </div>
            <div class="ml-auto bg-green pointer px-2 py-1 text-white rounded inline-flex align-center">
                <svg class="w-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" fill="#5B5B5B">
                    <path d="M16 4C9.382813 4 4 9.382813 4 16C4 22.617188 9.382813 28 16 28C22.617188 28 28 22.617188 28 16C28 9.382813 22.617188 4 16 4 Z M 16 6C21.535156 6 26 10.464844 26 16C26 21.535156 21.535156 26 16 26C10.464844 26 6 21.535156 6 16C6 10.464844 10.464844 6 16 6 Z M 16 10C13.800781 10 12 11.800781 12 14L14 14C14 12.882813 14.882813 12 16 12C17.117188 12 18 12.882813 18 14C18 14.765625 17.507813 15.445313 16.78125 15.6875L16.375 15.8125C15.558594 16.082031 15 16.863281 15 17.71875L15 19L17 19L17 17.71875L17.40625 17.59375C18.945313 17.082031 20 15.621094 20 14C20 11.800781 18.199219 10 16 10 Z M 15 20L15 22L17 22L17 20Z" fill="#fff" />
                </svg>
            <span class="text-base ml-1"> Help </span>
            </div>
        </div>
        <div class="p-4 text-center w-50p mx-auto my-16" style="border: 1px solid #7432b8; border-radius: 25px">
            <h2 class="text-2xl mt-2 mb-4 text-uppercase ">Set Password</h2>

            <div class="flex align-center flex-col">
                <InputFieldComponent
                    type="password"
                    label="Write Password"
                    class="my-4 w-75p"
                />

                <InputFieldComponent
                    type="password"
                    label="Re-Write Password"
                    class="my-4 w-75p"
                />
            </div>
        </div>
        <div class="md-layout mt-4">
            <div class="md-layout-item flex align-center">
                <div>
                    <p class="text-base font-semibold">
                        <span class="opacity-75">Copyright&copy; all rights reserved by </span>
                        <span>APICL</span>
                    </p>
                </div>
                <md-button class="ml-auto text-uppercase">Clear all</md-button>
                <Button
                    :loading="loading"
                    class="bg-victoria rounded text-white mr-0"
                    label="Save & Next"
                    type="button"
                    :disabled="terms"
                    @click="submit"
                    >
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import { Button, InputFieldComponent } from '@/components';
export default {
    data() {
        return {
            loading: false,
            terms: this.$route.name == 'basicInformation',
            menus : [
                {name: 'Basic info', path: 'basicInformation'},
                {name: 'Details', path: 'registrationDetails'},
                {name: 'Course', path: 'registrationCourse'},
                {name: 'Confirmation', path: 'registrationConfirmation'},
                {name: 'Payment', path: 'registrationPayment'},
                {name: 'Successfull', path: 'registrationSuccess'},
            ]
        }
    },
    components: {
        Button,
        InputFieldComponent
    },
    methods: {
        submit() {
            this.$router.push({
                name: 'registrationCourse'
            })
        }
    }
}
</script>

<style lang="scss">
    .progress-bar {
        display: flex;
        min-height: 35px;
    }
    .progress-bar li {
        position: relative;
        flex: 1 1 100%;
        &:before {
            content: '';
            position: absolute;
            width: 18px;
            height: 18px;
            border: 4px solid #876ba7;
            border-radius: 50%;
            z-index: 5;
            background: #fff;
        }
        &:after {
            z-index: 1;
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            top: 20%;
            background: #d9f4fc;
        }
    }
    .progress-bar li.is-ompleted {
        &::before {
            border: 4px solid #6c2bb7;
        }
        &:after {
            background: #02b5e6; 
        }
    }
    .progress-bar li:last-child {
        &::after {
            display: none;
        }
    }
</style>