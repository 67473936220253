<template>
  <div>
    <div class="rounded p-4" style="background: #f3fdf2">
      <div class="flex justify-between">
        <div>
          <p class="mb-2 text-base text-uppercase">Your Selected Course</p>
          <h2 class="text-2xl text-primary">
            {{ formDetails.packages ? formDetails.packages.name : "" }}
          </h2>
        </div>
        <div>
          <span class="discount-price"
            >${{ formDetails.packages && formDetails.packages.package_detail? formDetails.packages.package_detail.old_price : "" }}</span
          >
          <h2 class="mt-4 text-2xl font-bold">
            ${{ formDetails.packages && formDetails.packages.package_detail? formDetails.packages.package_detail.new_price : "" }}
          </h2>
        </div>
      </div>
    </div>
    <div class="text-right mt-2 mb-3">
      <span
        class="text-primary pointer font-semibold text-base text-uppercase text-underline"
        >Back to coruse</span
      >
    </div>

    <div class="p-4 rounded" style="background: #f7f4fc">
      <div class="flex justify-between align-center">
        <p class="text-base text-italic opacity-75 text-uppercase">Course Name</p>
        <p class="text-base text-italic opacity-75 text-uppercase">Price</p>
      </div>

      <div class="flex justify-between align-center my-2">
        <h3>{{ formDetails.packages ? formDetails.packages.name : "" }}</h3>
        <h3>${{ this.coursePrice() }}</h3>
      </div>
      <div class="flex justify-between align-center my-2">
        <p class="text-italic text-base font-semibold opacity-75">Subtotal</p>
        <h3>${{ this.coursePrice() }}</h3>
      </div>

      <div class="flex justify-between align-center my-2">
        <p class="text-italic text-base text-primary font-semibold">Promocode</p>
        <InputFieldComponent label-class="text-sm" type="text" class="w-20 ml-5" inputClass='p-1 text-right' @input="checkPromoCode" v-model="promocode" />

      </div>
      <div class="flex justify-between align-center my-2">
        <p class="text-italic text-base font-semibold opacity-75">Discount</p>
        <h3 class="opacity-75">{{ discount }}</h3>
      </div>
      <div class="flex justify-between align-center my-2">
        <h3 class="text-primary">Grand Total</h3>
        <h3 class="text-primary">${{ grandTotal }}</h3>
      </div>
    </div>

    <div class="my-4">
      <label for="input" class="mb-1 pr-4 text-uppercase font-bold block text-base"
        >How You Want TO Pay US
      </label>
      <div>
        <md-radio v-model="form.payWith" value="online">Pay Online</md-radio>
      </div>
      <div>
        <md-radio v-model="form.payWith" value="branch">Pay At Branch</md-radio>
      </div>
    </div>

    <StripeCard v-if="form.payWith == 'online'" ref="stripeCard" @getObject="getStripeResponse" :payButton=false :amount="grandTotal" />

    <PayLater v-if="form.payWith == 'branch'" />

    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
        :loading="loading"
        class="bg-victoria rounded text-white mr-0"
        label="Save & Exit"
        type="button"
        @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import StripeCard from "@/store/StripeCard.vue";
import { InputFieldComponent, Button } from "@/components";
import PayLater from "../../components/molecule/registration/PayLater";
import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    InputFieldComponent,
    Button,
    PayLater,
    StripeCard
  },

  data() {
    return {
      loading: false,
      radio: false,
      promocode: '',
      form: {
        payWith: "branch",
      },
      formDetails: {},
      discount: 0.0,
      invoiceInstalment: {},
      invoiceInstalmentResponse: {},
      transaction:{},
      promo_code_id:''
    };
  },

  computed: {
    grandTotal() {
      if (this.formDetails.packages && this.formDetails.packages.package_detail) {
        return this.formDetails.packages.package_detail.new_price - this.discount;
      }
      return 0.0;
    },
  },

  methods: {
    ...mapActions({
      actInvoiceInstalment: "registration/actInvoiceInstalment",
      actGetPromoCode: "registration/actGetPromoCode",
      actTransaction: "registration/actTransaction",
    }),
    ...mapMutations({
      setShowSnackbar: "setShowSnackbar"
    }),
    getFormDetails() {
      this.formDetails = this.getLocal("registration-student-info");

    },

    currentDate() {
      const current = new Date();
      const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
      return date;
    },

    saveTransaction(response){
     
        this.transaction = {
          "invoice_id": this.invoiceInstalmentResponse.invoice_details.invoice_id,
          "payment_date": this.currentDate(),
          "total": response.amount/100,
          "transaction_source": "STRIPE",
          "transaction_details": response,
          "status": "SUCCESSFULL"
        }

      this.actTransaction(this.transaction).then(() => {
        
        localStorage.setItem("registration-success", true);
        this.remove("registration-student-info");
        this.remove("registration-student-info-menu");
        this.$emit("menu", this.$route.name);
        this.$router.push({
            name: "registrationSuccess",
            params: this.formDetails,
        });

      }).finally(() => this.loading = false)
        
    },

    saveInvoiceInstalment(){
      let existData = this.getLocal('registration-student-info');
     
      this.invoiceInstalment = {
         
          "package_id": existData.packages && existData.packages[0]?existData.packages[0]:'',
          "user_id": existData.user_id,
          "promo_code_id": this.promo_code_id?this.promo_code_id:'',
          "payment_type": this.form.payWith.toUpperCase(),
          "payment_for": "STUDENT_ONBOARDING",
          "number_of_instalments":1,
          "instalment_tracker":[
              {
                  "payable_amount": this.grandTotal,
                  "due_date": this.currentDate(),
                  "status": true
              }
          ]
      }
      this.actInvoiceInstalment(this.invoiceInstalment).then(({items}) => {


          this.invoiceInstalmentResponse = items;

          if(this.form.payWith == 'branch'){
            
            localStorage.setItem("registration-success", true);
            this.remove("registration-student-info");
            this.remove("registration-student-info-menu");
            this.$emit("menu", this.$route.name);
            this.$router.push({
                name: "registrationSuccess",
                params: this.formDetails,
            });

          }
      }).finally(() => this.loading = false)
    },

    getStripeResponse(response){
   
        if(response.status=="succeeded"){
            this.saveTransaction(response);
            this.setShowSnackbar('Payment succeeded');
        }else{
            this.setShowSnackbar('Payment not succeeded');
        }
    },
    coursePrice(){
      return this.formDetails.packages && this.formDetails.packages.package_detail? this.formDetails.packages.package_detail.new_price : '';
    },
    checkPromoCode(code){
        this.actGetPromoCode(code).then(({items}) => {
          let result = items;
          this.promo_code_id = result.id;
          if(result.discount_type=='PERCENTAGE'){
            if(this.coursePrice() > 0 && result.discount > 0){
              this.discount = (this.coursePrice()*result.discount)/100;
            }
          }else{
              this.discount = result.discount;
          }
          
      }).catch(error => {
          console.log(error);
          this.discount = 0
      })
      .finally(() => this.loading = false)
    },
    submit() {
      this.loading = true;

      this.saveInvoiceInstalment();

      if(this.form.payWith == 'online'){
        this.$refs.stripeCard.paymentSubmit();
      }
    },

    clearAll() {
      this.$emit("clear-all", "");
    },
  },

  created() {
    this.getFormDetails();
  },
};
</script>

<style lang="scss">
.discount-price {
  font-size: 1rem;
  font-weight: 700;
  color: gainsboro;
  position: relative;

  &:after {
    position: absolute;
    left: -11px;
    top: 12px;
    content: "";
    width: 130%;
    height: 1px;
    background: lightgrey;
  }
}
</style>
